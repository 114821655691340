import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import { LoadingButton } from '@material-ui/lab';
import { useState } from 'react';

const icons = {
  error: (
    <ErrorIcon
      color="white"
      fontSize="large"
    />
  ),
  warning: (
    <WarningIcon
      color="white"
      fontSize="large"
    />
  ),
  info: (
    <InfoIcon
      color="white"
      fontSize="large"
    />
  )
};

export const ConfirmationDialog = (props) => {
  const { confirmDialog, setConfirmDialog } = props;
  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = useState(false);

  return (
    <Dialog
      onClose={() => setConfirmDialog({ ...confirmDialog, open: false })}
      open={confirmDialog?.open}
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
    // {...other}
    >
      <DialogTitle
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        {icons[confirmDialog?.variant]}
        <Typography
          color="inherit"
          sx={{ ml: 2 }}
          variant="inherit"
        >
          {confirmDialog?.title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          color="textPrimary"
          variant="body1"
        >
          {confirmDialog?.message}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body1"
        >
          {confirmDialog?.itemName}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => setConfirmDialog({ ...confirmDialog, open: false })}
          variant="contained"
        >
          Cancel
        </Button>
        <LoadingButton
          loading={disabled}
          color="success"
          onClick={() => {
            confirmDialog?.onConfirm();
            setDisabled(true);
            setTimeout(() => {
              setDisabled(false);
            }, 2000);
          }}
          variant="contained"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  confirmDialog: PropTypes.object,
  setConfirmDialog: PropTypes.func,
};
