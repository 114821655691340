import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../hooks/use-auth';
// import { NotFound } from '../containers/not-found';

export const RoleBasedGuard = ({ children, allowedRoles }) => {
  const { isAuthenticated, user } = useAuth();
  if (!user) return <Navigate to="/login" replace />;
  const userHasRequiredRole = allowedRoles.includes(user.group);
  if (isAuthenticated) {
    // console.log('userData', JSON.stringify((user.group.toLowerCase() ===
    // 'Super Admin'.toLowerCase()), null, 2));
    if (user?.group.toLowerCase() === 'Cashier'?.toLowerCase() && !userHasRequiredRole) {
      return (
        <Navigate
          to="/pos/sales"
          replace
        />
      );
    }

    if (user.group.toLowerCase() === 'Branch Admin'.toLowerCase() && !userHasRequiredRole) {
      return (
        <Navigate
          to="/pos/sales"
          replace
        />
      );
    }

    if (user.group.toLowerCase() === 'Super Admin'.toLowerCase() && !userHasRequiredRole) {
      return (
        <Navigate
          to="/users"
          replace
        />
      );
    }
    if (user.group.toLowerCase() === 'Waiver Handler'.toLowerCase() && !userHasRequiredRole) {
      return (
        <Navigate
          to="/waiver-form"
          replace
        />
      );
    }
  }
  return <>{children}</>;
};

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  allowedRoles: PropTypes.array
};
