import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Grid,
  Paper,
  Link,
  Divider,
  Typography
} from '@material-ui/core';
import { useSettings } from '../contexts/settings-context';
import { LoginJwt } from '../components/auth/login-jwt';
import gtm from '../lib/gtm';
import loginIllustration from '../icons/illustrations/login-img.jpg';

export const Login = () => {
  const { settings } = useSettings();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Login | Rack Dashboard</title>
      </Helmet>
      {/* <AppBar
        elevation={0}
        sx={{ backgroundColor: 'background.paper' }}
      >
        <Container maxWidth="md">
          <Toolbar
            disableGutters
            sx={{ height: 64 }}
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Toolbar>
        </Container>
      </AppBar> */}
      <Box>
        <Grid
          container
          sx={{
            height: '100vh',
            // overflowY: 'hidden'
          }}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <Paper
              sx={{
                position: 'relative',
                borderRadius: '0px',
                width: '100%',
                minHeight: '100vh',
                background: `url(${loginIllustration})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                overflowY: 'hidden',
                padding: '40px'
              }}
            />
            <Typography
              fontSize="small"
              sx={{
                position: 'absolute',
                bottom: '20px',
                left: '20px',
                color: '#868998'
              }}
            >
              © Copyright. RACK. All Rights Reserved
            </Typography>
            {/* <RouterLink
              to="/"
            >
              <Logo />
            </RouterLink> */}
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <Paper
              sx={{
                borderRadius: '0px',
                width: '100%',
                minHeight: '100vh',
                background: `linear-gradient(-45deg, ${settings.theme === 'light' ? '#ffffff' : '#111318'} 50%, #f26633 50.3%)`,
                overflowY: 'hidden',
                padding: '40px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  marginBottom: '60px'
                }}
              >
                <LoginJwt />
              </Grid>
              <Grid
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                item
                xs={12}
              >
                <Link
                  color="textSecondary"
                  href="http://www.rack.business"
                  target="_blank"
                  variant="body2"
                  mr={1}
                >
                  Rack website
                </Link>
                <Divider
                  color="textSecondary"
                  orientation="vertical"
                  flexItem
                  sx={{
                    marginRight: 1,
                    background: '#868998',
                    width: '1.9px'
                  }}
                />
                <Link
                  color="textSecondary"
                  href="https://www.rack.business/privacy-policy/"
                  target="_blank"
                  variant="body2"
                  mr={1}
                >
                  Privacy Policy
                </Link>
                <Divider
                  color="textSecondary"
                  orientation="vertical"
                  flexItem
                  sx={{
                    marginRight: 1,
                    background: '#868998',
                    width: '1.9px'
                  }}
                />
                <Link
                  color="textSecondary"
                  href="https://www.rack.business/privacy-policy/"
                  target="_blank"
                  variant="body2"
                  mr={1}
                >
                  Terms & Conditions
                </Link>
                <Divider
                  color="textSecondary"
                  orientation="vertical"
                  flexItem
                  sx={{
                    marginRight: 1,
                    background: '#868998',
                    width: '1.9px'
                  }}
                />
                <Link
                  color="textSecondary"
                  href="http://www.rack.business"
                  target="_blank"
                  variant="body2"
                >
                  Contact Us
                </Link>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
