/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Grid,
  IconButton,
} from '@material-ui/core';
import moment from 'moment';
import { InputField } from '../input-field';
import { Plus as PlusIcon } from '../../icons/plus';
import { Trash as TrashIcon } from '../../icons/trash';
import { DateField } from '../date-field';

export const CrmCustomerBeneficiaries = ({ beneficiary, onChange, onRemove }) => {
  const handleFieldChange = (field, value) => {
    onChange({ ...beneficiary, [field]: value });
  };
  const location = useLocation();

  return (
    <>
      <Grid item xs={location.pathname === '/waiver-form' ? 5 : 3}>
        <InputField
          fullWidth
          label="Child First Name"
          type="text"
          placeholder="First Name"
          value={beneficiary.firstName}
          onChange={(e) => handleFieldChange('firstName', e.target.value)}
          required
        // ...other TextField props
        />
      </Grid>
      <Grid item xs={location.pathname === '/waiver-form' ? 5 : 3}>
        <InputField
          fullWidth
          label="Child Last Name"
          type="text"
          placeholder="Last Name"
          value={beneficiary.lastName}
          onChange={(e) => handleFieldChange('lastName', e.target.value)}
          required
        // ...other TextField props
        />
      </Grid>
      {location.pathname !== '/waiver-form' && (
        <Grid item xs={5}>
          <DateField
            fullWidth
            label="Child Date of Birth"
            name="dateOfBirth"
            onChange={(date) => handleFieldChange('dob', moment(date).format('l'))}
            placeholder="dd/mm/yyyy"
            value={beneficiary.dob}
            maxDate={new Date()}
          />
        </Grid>
      )}

      <Grid item xs={1}>
        <IconButton onClick={onRemove}>
          <TrashIcon />
        </IconButton>
      </Grid>
    </>
  );
};

CrmCustomerBeneficiaries.propTypes = {
  beneficiary: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
