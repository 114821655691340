import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';

const icons = {
  error: (
    <ErrorIcon
      color="white"
      fontSize="large"
    />
  ),
  warning: (
    <WarningIcon
      color="white"
      fontSize="large"
    />
  ),
  info: (
    <InfoIcon
      color="white"
      fontSize="large"
    />
  )
};

export const ChangeBranchDialog = (props) => {
  const {
    open,
    onClose,
    onExited,
    handleOrganizationChange,
    handleClose,
    selectedBranch,
    ...other
  } = props;
  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
      TransitionProps={{
        onExited: () => {
          onExited?.();
        }
      }}
      {...other}
    >
      <DialogTitle
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        {icons.warning}
        <Typography
          color="inherit"
          sx={{ ml: 2 }}
          variant="inherit"
        >
          Change Branch
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          color="textPrimary"
          variant="body1"
        >
          {`You are about to change to ${selectedBranch?.branchName} branch, proceed?`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            onClose();
            handleClose();
          }}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          color="success"
          onClick={() => {
            onClose();
            handleOrganizationChange(selectedBranch.id);
          }}
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ChangeBranchDialog.defaultProps = {
  open: false
};

ChangeBranchDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExited: PropTypes.func,
  handleClose: PropTypes.func,
  handleOrganizationChange: PropTypes.func,
  selectedBranch: PropTypes.object
};
