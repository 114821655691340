import { useAuth } from '../hooks/use-auth';
import { useSettings } from '../contexts/settings-context';

export const Logo = () => {
  const { isAuthenticated } = useAuth();
  const { settings } = useSettings();

  if (!isAuthenticated) {
    if (settings.theme === 'light') {
      return (
        <img
          src="/Logo-Rack1.png"
          alt="Rack Logo"
          style={{
            width: '180px',
            height: 'auto'
          }}
        />
      );
    }
    return (
      <img
        src="/Logo-Rack3.png"
        alt="Rack Logo"
        style={{
          width: '180px',
          height: 'auto'
        }}
      />
    );
  }
  return (
    <img
      src="/Logo-Rack3.png"
      alt="Rack Logo"
      style={{
        width: '120px',
        height: 'auto'
      }}
    />
  );
};
