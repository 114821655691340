import React from 'react';
import { Dialog, DialogContent, Button, Typography, Grid, DialogTitle, IconButton } from '@material-ui/core';
import { useError } from '../contexts/error-context';
import { X as XIcon } from '../icons/x';
import WarningIllustration from '../icons/warning-illustration.svg';

const ErrorDialog = () => {
  const { error, clearError, errorCode, actionFunc, actionButtonTitle } = useError();

  return (
    <Dialog
      open={!!error}
      onClose={() => clearError()}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: { borderRadius: 16, padding: '20px', minHeight: '400px', minWidth: '800px' }
      }}
    >
      <DialogTitle>
        <IconButton
          onClick={() => clearError()}
          sx={{
            color: '#00000',
            position: 'absolute',
            right: 8,
            top: 8,
            '&:hover': {
              bgcolor: 'transparent',
              color: 'text.secondary'
            }
          }}
        >
          <XIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          alignItems="center"
          direction="column"
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            sm={4}
          >
            <img
              src={WarningIllustration}
              alt="Warning"
              style={{ maxWidth: '100%', height: '200px', marginLeft: 'auto' }}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={8}
            alignItems="center"
            direction="column"
            justifyContent="center"
          >
            <Typography
              variant="h2"
              style={{ color: '#FF6A3D', fontWeight: 'bold', alignSelf: 'center' }}
            >
              {errorCode}
            </Typography>
            <Typography
              variant="h6"
              style={{ color: '#FF6A3D' }}
            >
              OOPS! An error occurred
            </Typography>
            <Typography
              variant="body1"
              style={{ marginTop: '10px', color: '#666' }}
            >
              {error}
            </Typography>
            <Button
              onClick={() => {
                clearError();
                actionFunc();
              }}
              style={{
                backgroundColor: '#FF6A3D',
                color: '#FFF',
                borderRadius: 25,
                padding: '10px 20px',
                marginTop: '10px'
              }}
            >
              {actionButtonTitle}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorDialog;
