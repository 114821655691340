import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, Outlet, useLocation, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Divider,
  Skeleton,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';

// import { productApi } from '../api/product';
// import { ActionsMenu } from '../components/actions-menu';
// import { Status } from '../components/status';
import { useMounted } from '../hooks/use-mounted';
import { ArrowLeft as ArrowLeftIcon } from '../icons/arrow-left';
import { ExclamationOutlined as ExclamationOutlinedIcon } from '../icons/exclamation-outlined';
import jwtAxios from '../api/jwt-api';

export const Merchant = () => {
  const mounted = useMounted();
  const location = useLocation();
  const params = useParams();
  const [merchantState, setMerchantState] = useState({ isLoading: true });

  const tabs = [
    {
      href: `/merchants/${params.merchantId}`,
      label: 'Summary'
    },
    {
      href: `/merchants/${params.merchantId}/billing`,
      label: 'Billing'
    },
    {
      href: `/merchants/${params.merchantId}/branches`,
      label: 'Branches'
    },
    {
      href: `/merchants/${params.merchantId}/users`,
      label: 'Users'
    },
    {
      href: `/merchants/${params.merchantId}/user-group`,
      label: 'User Group'
    },
    {
      href: `/merchants/${params.merchantId}/notifications/templates`,
      label: 'Notifications'
    },
    {
      href: `/merchants/${params.merchantId}/brands`,
      label: 'Brands'
    },
    {
      href: `/merchants/${params.merchantId}/units`,
      label: 'Units'
    },
    {
      href: `/merchants/${params.merchantId}/tax-rate`,
      label: 'Tax Rate'
    },
    {
      href: `/merchants/${params.merchantId}/variation`,
      label: 'Variation'
    },
    {
      href: `/merchants/${params.merchantId}/category`,
      label: 'Category'
    },
    {
      href: `/merchants/${params.merchantId}/product`,
      label: 'Products'
    },
    {
      href: `/merchants/${params.merchantId}/product-add`,
      label: 'Add Product'
    },
    {
      href: `/merchants/${params.merchantId}/supplier`,
      label: 'Suppliers'
    },
    {
      href: `/merchants/${params.merchantId}/purchase`,
      label: 'Purchase'
    },
    {
      href: `/merchants/${params.merchantId}/purchase-return`,
      label: 'Purchase Return'
    },
    {
      href: `/merchants/${params.merchantId}/customers`,
      label: 'Customers'
    },
    {
      href: `/merchants/${params.merchantId}/discounts`,
      label: 'Discounts'
    },
    {
      href: `/merchants/${params.merchantId}/stock-transfer`,
      label: 'Stock Transfer'
    },
    {
      href: `/merchants/${params.merchantId}/payments`,
      label: 'Payments'
    },
    // {
    //   href: `/merchants/${params.merchantId}/opening-stock`,
    //   label: 'Opening Stock'
    // }
  ];

  const getMerchant = useCallback(async () => {
    setMerchantState(() => ({ isLoading: true }));

    try {
      const res = await jwtAxios.get(`merchant/${params.merchantId}`);
      if (mounted.current) {
        setMerchantState(() => ({
          isLoading: false,
          data: res.data.data
        }));
      }
    } catch (err) {
      console.error(err);

      if (mounted.current) {
        setMerchantState(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }, []);

  useEffect(() => {
    getMerchant().catch(console.error);
  }, []);

  // const handleSendInvoice = () => {
  //   toast.error('This action is not available on demo');
  // };

  // const actions = [
  //   {
  //     label: 'Send Invoice to Customer',
  //     onClick: handleSendInvoice
  //   },
  //   {
  //     label: 'Discontinue Product',
  //     onClick: handleOpenDiscontinueDialog
  //   },
  //   {
  //     label: 'Archive Product',
  //     onClick: handleOpenArchiveDialog
  //   }
  // ];

  const renderContent = () => {
    if (merchantState.isLoading) {
      return (
        <Box sx={{ py: 4 }}>
          <Skeleton height={42} />
          <Skeleton />
          <Skeleton />
        </Box>
      );
    }

    if (merchantState.error) {
      return (
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'neutral.100',
              display: 'flex',
              flexDirection: 'column',
              p: 3
            }}
          >
            <ExclamationOutlinedIcon />
            <Typography
              color="textSecondary"
              sx={{ mt: 2 }}
              variant="body2"
            >
              {merchantState.error}
            </Typography>
          </Box>
        </Box>
      );
    }

    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box sx={{ mb: 2 }}>
            <Button
              color="primary"
              component={RouterLink}
              startIcon={<ArrowLeftIcon />}
              to="/merchants"
              variant="text"
            >
              Merchants
            </Button>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography
              color="textPrimary"
              variant="h4"
            >
              {merchantState.data.businessName}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            {/* <ActionsMenu actions={actions} /> */}
          </Box>
          {/* <Box sx={{ mt: 2 }}>
            {productState.data.enabled ? (
              <Status
                color="success.main"
                label="Active"
              />
            ) : (
              <Status
                color="info.main"
                label="Disabled"
              />
            )}
          </Box> */}
          <Tabs
            allowScrollButtonsMobile
            sx={{ mt: 4 }}
            value={tabs.findIndex((tab) => tab.href === location.pathname)}
            variant="scrollable"
          >
            {tabs.map((option) => (
              <Tab
                component={RouterLink}
                key={option.href}
                label={option.label}
                to={option.href}
              />
            ))}
          </Tabs>
          <Divider />
        </Box>
        <Outlet />
      </>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        {renderContent()}
      </Container>
    </Box>
  );
};
