/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, CircularProgress, Stack, Box, Grid } from '@material-ui/core';
import { Trash as TrashIcon } from '../../icons/trash';
import { Eye as EyeIcon } from '../../icons/eye';

// Define a reusable FileUpload component
function FileUpload({ formik, handleFileUpload, fileIndex, fileUploadProgress }) {
  const fieldName = formik.values.formikFileUploads[fileIndex]?.fieldName;
  const fileUrl = formik.values.formikFileUploads[fileIndex]?.fileUrl;
  const fileName = formik.values.formikFileUploads[fileIndex]?.fileName;
  const handleDeleteFile = () => {
    formik.setFieldValue('formikFileUploads', formik.values.formikFileUploads.filter((file) => file.fieldName !== fieldName));
  };
  if (fileUploadProgress) {
    return (
      <Grid
        xs={12}
        item
      >
        <CircularProgress
          color="primary"
          size={25}
        />
      </Grid>
    );
  }
  if (fileUrl) {
    return (
      <Grid
        xs={12}
        item
      >
        <Stack
          direction="column"
          spacing={1}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center'
            }}
          >
            <Typography
              variant="body"
              fontSize="small"
            >
              {fileName || ''}
            </Typography>
            <Button
              variant="text"
              startIcon={<EyeIcon />}
            >
              <a
                href={fileUrl}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none', color: '#f26633' }}
              >
                View File
              </a>
            </Button>
            <Button
              variant="text"
              color="error"
              startIcon={<TrashIcon />}
              onClick={handleDeleteFile}
            >
              Delete File
            </Button>
          </Box>
        </Stack>
      </Grid>
    );
  }

  return (

    <Grid
      xs={12}
      item
    >
      <Stack
        direction="column"
        spacing={1}
        sx={{ marginTop: '20px' }}
      >
        <input
          type="file"
          name="files"
          onChange={(e) => handleFileUpload(e, fileIndex || 0)}
          label="Attach document(pdf, jpeg, jpg, png)"
        />
      </Stack>
    </Grid>
  );
}

FileUpload.propTypes = {
  formik: PropTypes.object.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  fileIndex: PropTypes.number.isRequired,
  fileUploadProgress: PropTypes.bool.isRequired
};

export default FileUpload;
