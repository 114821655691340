import { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AppBar, Box, Button, Divider, Toolbar, IconButton } from '@material-ui/core';
import { ChevronDown as ChevronDownIcon } from '../icons/chevron-down';
import { useSettings } from '../contexts/settings-context';
import { Moon as MoonIcon } from '../icons/moon';
import { Sun as SunIcon } from '../icons/sun';
import { AccountPopover } from './account-popover';
import { OrganizationPopover } from './organization-popover';
import { Logo } from './logo';
import { DashboardNavbarMenu } from './dashboard-navbar-menu';
import { NotificationsPopover } from './notifications-popover';
import { LanguagePopover } from './language-popover';
import { useAuth } from '../hooks/use-auth';
import { Plus as PlusIcon } from '../icons/plus';
import { useDialog } from '../hooks/use-dialog';
import jwtAxios from '../api/jwt-api';
import { CrmCustomerDialog } from './merchant/crm-customer-dialog';
import { getSelectedBranch } from '../utils/merchant-info';

export const DashboardNavbar = () => {
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { i18n, t } = useTranslation();
  const { settings, saveSettings } = useSettings();
  const { branchesList, handleChangeSelectedBranch } = useAuth();
  const [openMenu, setOpenMenu] = useState(false);
  const [darkMode, setDarkMode] = useState(settings.theme === 'dark');
  const [rtlDirection, setRtlDirection] = useState(settings.direction === 'rtl');
  const [customerDialogOpen, handleOpenCustomerDialog, handleCloseCustomerDialog] = useDialog();

  const loggedInUser = window.localStorage.getItem('user');
  const user = JSON.parse(loggedInUser);
  const params = useParams();
  const merchantId = params.merchantId ? params.merchantId : user.companyId;
  const branchId = getSelectedBranch()?.id;
  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    saveSettings({
      ...settings,
      language
    });
    toast.success(t('Language changed'));
  };

  const handleSwitchTheme = async () => {
    const preferredTheme = settings.theme === 'light' ? 'dark' : 'light';
    try {
      await jwtAxios.patch(`user/update-theme-color?preferredThemeColor=${preferredTheme}&userId=${user.userId}`);

      saveSettings({
        ...settings,
        theme: settings.theme === 'light' ? 'dark' : 'light'
      });

      setDarkMode(settings.theme === 'light');
      user.preferredThemeColor = preferredTheme;
      localStorage.setItem('user', JSON.stringify(user));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSwitchDirection = () => {
    saveSettings({
      ...settings,
      direction: settings.direction === 'ltr' ? 'rtl' : 'ltr'
    });

    setRtlDirection(settings.direction === 'rtl');
  };

  const handleOrganizationChange = (organizationId) => {
    const newOrganization = branchesList.find((organization) => organization.id
      === organizationId);

    if (!newOrganization) {
      return;
    }
    handleChangeSelectedBranch(newOrganization);
    window.location.reload(false);
  };

  const handleAddCustomer = async (variant, mode, helpers) => {
    const {
      dob,
      email,
      firstName,
      id,
      lastName,
      phoneNumber,
      shippingAddress,
      categoryId,
      buyersPIN,
      base64Image,
      base64Image2,
      extraDataMap,
      beneficiaryDTOS,
      fileName,
      fileName2
    } = variant;

    const filteredExtraDataMap = extraDataMap.filter((item) => item.value !== '');
    const updatedExtraDataMap = filteredExtraDataMap.length === 0
      ? null
      : Object.assign({}, ...filteredExtraDataMap.map((item) => ({ [item.id]: item.value })));

    const body = {
      dob,
      email,
      firstName,
      id,
      lastName,
      merchantId,
      branchId,
      phoneNumber,
      shippingAddress,
      categoryId,
      base64Image,
      base64Image2,
      extraDataMap: updatedExtraDataMap,
      beneficiaryDTOS,
      buyersPIN,
      fileName,
      fileName2
    };

    try {
      await jwtAxios.post('customer/create', body);
      helpers.setStatus({ success: true });
      helpers.setSubmitting(false);
      toast.success('Customer details saved');
      handleCloseCustomerDialog();
    } catch (error) {
      helpers.setStatus({ success: false });
      helpers.setErrors({ submit: error.response.data.error[0].description });
      helpers.setSubmitting(false);
      toast.error(error.response.data.error[0].description);
      console.log(error.message);
      console.log(error.response.data.error[0].description);
      console.log(error.response.status);
    }
  };

  return (
    <AppBar
      elevation={0}
      sx={{ backgroundColor: '#0b1a2a' }}
    >
      <Toolbar
        disableGutters
        sx={{
          alignItems: 'center',
          display: 'flex',
          minHeight: 64,
          px: 3,
          py: 1
        }}
      >
        <Box
          component={RouterLink}
          to="/"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Logo />
        </Box>
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            borderColor: 'rgba(255,255,255,0.1)',
            mx: 3
          }}
        />
        {(user.group === 'Merchant Admin' || user.group === 'Branch Admin' || user.group === 'Cashier' || user.group === 'Accountant') && (
          <OrganizationPopover
            currentOrganization={user.selectedBranch}
            onOrganizationChange={handleOrganizationChange}
            organizations={branchesList}
            sx={{
              display: {
                md: 'flex',
                xs: 'none'
              },
              mr: 2
            }}
          />
        )}
        <DashboardNavbarMenu
          onClose={() => setOpenMenu(false)}
          open={mdDown && openMenu}
        />
        <Button
          endIcon={(
            <ChevronDownIcon
              fontSize="small"
              sx={{
                ml: 2,
                transition: 'transform 250ms',
                transform: openMenu ? 'rotate(180deg)' : 'none'
              }}
            />
          )}
          onClick={() => setOpenMenu(true)}
          sx={{
            color: 'primary.contrastText',
            display: {
              md: 'none',
              xs: 'flex'
            }
          }}
          variant="text"
        >
          Menu
        </Button>
        {(user.group === 'Merchant Admin' || user.group === 'Branch Admin' || user.group === 'Cashier') && (
          <>
            <Button
              sx={{
                marginRight: '6px',
                display: {
                  lg: 'inline-flex',
                  xs: 'none'
                }
              }}
              variant="contained"
              component={RouterLink}
              size="medium"
              to="/pos/sales"
            >
              POS
            </Button>
            {/* <Button>
              Calculator
            </Button> */}
            <Button
              size="medium"
              startIcon={<PlusIcon fontSize="small" />}
              variant="contained"
              onClick={handleOpenCustomerDialog}
              sx={{
                display: {
                  lg: 'inline-flex',
                  xs: 'none'
                }
              }}
            >
              Customer
            </Button>
          </>
        )}

        <Box sx={{ flexGrow: 1 }} />
        <LanguagePopover
          language={i18n.language}
          onLanguageChange={handleLanguageChange}
          sx={{
            display: {
              md: 'inline-flex',
              xs: 'none'
            }
          }}
        />
        <IconButton
          color="inherit"
          onClick={handleSwitchTheme}
          sx={{
            mx: 2,
            display: {
              md: 'inline-flex',
              xs: 'none'
            }
          }}
        >
          {darkMode
            ? <SunIcon />
            : <MoonIcon />}
        </IconButton>
        <NotificationsPopover sx={{ mr: 2 }} />
        <AccountPopover
          currentOrganization={user.selectedBranch}
          darkMode={darkMode}
          onLanguageChange={handleLanguageChange}
          onOrganizationChange={handleOrganizationChange}
          onSwitchDirection={handleSwitchDirection}
          onSwitchTheme={handleSwitchTheme}
          organizations={branchesList}
          rtlDirection={rtlDirection}
        />
      </Toolbar>
      <CrmCustomerDialog
        onClose={handleCloseCustomerDialog}
        // onExited={handleExitedDialog}
        onVariantsChange={handleAddCustomer}
        open={customerDialogOpen}
      />
    </AppBar>
  );
};
