/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Autocomplete,
  Stack,
  InputAdornment,
  TextField,
  Chip,
  Divider,
  MenuItem,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { alpha } from '@material-ui/core/styles';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOn from '@material-ui/icons/LocationOn';
import moment from 'moment';
import { InputField } from '../input-field';
import { DateField } from '../date-field';
import jwtAxios from '../../api/jwt-api';
import { User as UserIcon } from '../../icons/user';
import { ConfirmationDialog } from '../confirmation-dialog';
import FileUpload from './crm-file-upload';
import { useMerchantContext } from '../../contexts/merchant-context';
import { CrmCustomerBeneficiaries } from './crm-customer-beneficiaries';
import { Plus as PlusIcon } from '../../icons/plus';
import { phoneRegExp } from '../../utils/phone-regex';

export const CrmCustomerDialog = (props) => {
  const { open, onClose, onExited, onVariantsChange, variant, ...other } = props;
  const [categoriesList, setCategoriesList] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [customFields, setCustomFields] = useState([]);
  const [fileUploadProgress, setFileUploadProgress] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({ open: false, itemName: '', message: '', title: '', variant: '' });
  const [beneficiaries, setBeneficiaries] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [merchantInfo, setMerchantInfo] = useState({});
  const { merchantData, isLoading, fetchMerchantData } = useMerchantContext();
  const params = useParams();
  const mode = variant && !variant?.tempCustomer ? 'update' : 'add';

  const loggedInUser = window.localStorage.getItem('user');
  const user = JSON.parse(loggedInUser);

  const merchantId = params.merchantId ? params.merchantId : user.companyId;

  useEffect(async () => {
    if (!isLoading && !merchantData?.id) {
      fetchMerchantData(merchantId);
    }
    try {
      const response = await jwtAxios.get(`custom-field/by-merchant/${merchantId}/2`);
      let activeFields = [];
      response.data.data.map((val) => {
        if (val.active === true) {
          activeFields = [...activeFields, val];
        }
        return true;
      });
      setCustomFields(activeFields);
    } catch (error) {
      console.log(error);
    }
    if (merchantData?.id) {
      setMerchantInfo(merchantData);
    }
  }, []);

  const updatedExtraDataMap = variant
    ? variant?.extraDataMap === null
      ? customFields.map((option) => {
        const properties = {
          id: option.id,
          type: option.type,
          value: '',
          configuredValues: option.configuredValues
        };
        return properties;
      })
      : customFields.map((option) => {
        const properties = {
          id: option.id,
          type: option.type,
          value: Object?.keys(variant?.extraDataMap || {}).map((key) => ({ // TODO: to review this
            id: key,
            value: variant?.extraDataMap[key]
          })).find((item) => Number(item.id) === option.id)?.value || '',
          configuredValues: option.configuredValues
        };
        return properties;
      })
    : customFields.map((option) => {
      const properties = {
        id: option.id,
        type: option.type,
        value: '',
        configuredValues: option.configuredValues
      };
      return properties;
    });

  // const updatedExtraDataMap = variant
  //   ? variant.extraDataMap === null
  //     ? [{ id: '', type: '', value: '', configuredValues: [] }]
  //     : Object.keys(variant?.extraDataMap).map((key) => ({
  //       id: key,
  //       value: variant?.extraDataMap[key],
  //       type: customFields.find((field) => field.id === Number(key)).type,
  //       configuredValues: customFields.find(
  //         (field) => field.id === Number(key)
  //       ).configuredValues }))
  //   : [
  //     {
  //       id: '',
  //       type: '',
  //       value: '',
  //       configuredValues: []
  //     }
  //   ];

  // In your main component, render the FileUpload components in a loop or map
  const handleAddBeneficiary = () => {
    setBeneficiaries([...beneficiaries, { firstName: '', lastName: '', dob: '' }]);
  };
  const fileUploadSections = [0, 1, 2, 3];
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dob: variant?.dob || null,
      email: variant?.email || '',
      firstName: variant?.firstName || '',
      lastName: variant?.lastName || '',
      phoneNumber: variant?.phoneNumber || '',
      buyersPIN: variant?.buyersPIN || '',
      merchantId: variant?.merchantId || params.merchantId,
      categoryId: variant?.customerCategory?.id || null,
      deliveryLocation: variant?.deliveryLocation || '',
      shippingAddress: variant?.shippingAddress || '',
      base64EncodedString: variant?.base64EncodedString || '',
      base64Image: variant?.base64Image || '',
      base64Image2: variant?.base64Image2 || '',
      base64Image3: variant?.base64Image || '',
      base64Image4: variant?.base64Image2 || '',
      beneficiaryDTOS: (mode === 'update' || variant?.tempCustomer) ? variant?.beneficiaryDTOS : [] || [],
      // loop around the fileUploadSections array
      formikFileUploads: fileUploadSections.map((fileIndex) => ({
        fieldName: fileIndex === 0 ? 'fileUrl' : `fileUrl${fileIndex + 1}` || '',
        fileUrl: fileIndex === 0 ? variant?.fileUrl : variant?.[`fileUrl${fileIndex + 1}`] || '',
        fileName: fileIndex === 0 ? variant?.fileName : variant?.[`fileName${fileIndex + 1}`] || ''
      })),
      extraDataMap: updatedExtraDataMap,
      submit: null
    },
    validationSchema: Yup.object().shape({
      dob: Yup.string()
        .nullable(),
      email: Yup.string()
        .email('Invalid email'),
      firstName: Yup.string()
        .required('First Name is required'),
      lastName: Yup.string()
        .required('Last Name is required'),
      phoneNumber: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone Number is required'),
      buyersPIN: merchantData?.integratedWithETIMS ? Yup.string().min(8, 'too short').max(11, 'too long')
        // starts and ends with an alphabet
        .matches(/^[a-zA-Z].*[a-zA-Z]$/, 'Invalid PIN') : Yup.string(),
    }),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, helpers) => {
      helpers.setSubmitting(true);
      formik.setSubmitting(true);
      try {
        // remove beneficiaryDTOS from formik values
        setBeneficiaries([]);
        onVariantsChange?.({ ...variant, ...values }, mode, helpers);
      } catch (err) {
        console.error(err);
      } finally {
        setBeneficiaries([]);
        setTimeout(() => {
          helpers.setSubmitting(false);
          formik.setSubmitting(false);
        }, 1000); // Set the timeout duration in milliseconds (e.g., 1000ms = 1 second)
      }
    }
  });

  const handleBeneficiaryChange = (index, updatedBeneficiary) => {
    const updatedBeneficiaries = [...beneficiaries];
    updatedBeneficiaries[index] = updatedBeneficiary;
    setBeneficiaries(updatedBeneficiaries);
    // set formik beneficiaryDTOS
    formik.setFieldValue('beneficiaryDTOS', [
      ...formik.values.beneficiaryDTOS.slice(0, index),
      updatedBeneficiary
    ]);
  };

  const handleRemoveBeneficiary = (index) => {
    const updatedBeneficiaries = beneficiaries.filter(
      (_, i) => i !== index
    );
    setBeneficiaries(updatedBeneficiaries);
    // set formik beneficiaryDTOS
    formik.setFieldValue('beneficiaryDTOS', [
      ...formik.values.beneficiaryDTOS.slice(0, index),
      ...formik.values.beneficiaryDTOS.slice(index + 1)
    ]);
  };

  useEffect(async () => {
    try {
      const response = await jwtAxios.get(`customer/customer-categories-by-merchant/${merchantId}?searchKey=${categoryName}`);
      setCategoriesList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (variant?.beneficiaryDTOS) {
      setBeneficiaries(variant?.beneficiaryDTOS);
    }
  }, [variant]);

  const convertToBase64 = (file) => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result.split(',')[1]);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });

  const handleFileUpload = async (e, fileIndex) => {
    try {
      setFileUploadProgress(true);

      const selectedFile = e.target.files[0];
      const fieldName = fileIndex === null ? 'fileUrl' : `fileUrl${fileIndex}`;
      const file = e.target.files[0];
      const base64EncodedString = await convertToBase64(file);

      // Create a copy of the formikFileUploads array
      const updatedFileUploads = [...formik.values.formikFileUploads];

      // Update the fileUrl and fileName for the specified index
      updatedFileUploads[fileIndex || 0] = {
        fieldName,
        fileUrl: base64EncodedString,
        fileName: selectedFile.name
      };

      // Set the updated array in formik
      formik.setFieldValue('formikFileUploads', updatedFileUploads);
      const trueIndex = fileIndex !== 0 ? parseInt(fileIndex, 10) + 1 : '';
      // set base64 index value
      formik.setFieldValue(`base64Image${trueIndex}`, base64EncodedString);
      setFileUploadProgress(false);
    } catch (error) {
      console.log(error);
      setFileUploadProgress(false);
    }
  };

  const getItemError = (index, property) => formik?.touched?.extraDataMap
    && formik?.errors?.extraDataMap
    && formik?.touched?.extraDataMap[index]?.[property]
    && formik?.errors?.extraDataMap[index]?.[property];

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="md"
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
      TransitionProps={{
        onExited: () => {
          onExited?.();
          formik.resetForm();
        }
      }}
      {...other}
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
        >
          <UserIcon
            sx={{
              color: 'white',
              marginRight: '30px'
            }}
          />
          {mode === 'update' ? 'Update Customer' : 'Add Customer'}
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={4}
          >
            <InputField
              error={Boolean(formik.touched.firstName && formik.errors.firstName)}
              fullWidth
              helperText={formik.touched.firstName && formik.errors.firstName}
              label="First Name"
              required
              name="firstName"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="Enter First Name"
              value={formik.values.firstName}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <UserIcon
                      fontSize="small"
                      sx={{ mr: '10px' }}
                    />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <InputField
              error={Boolean(formik.touched.lastName && formik.errors.lastName)}
              fullWidth
              helperText={formik.touched.lastName && formik.errors.lastName}
              label="Last Name"
              name="lastName"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="Enter Last Name"
              value={formik.values.lastName}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <UserIcon
                      fontSize="small"
                      sx={{ mr: '10px' }}
                    />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <InputField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email Address"
              name="email"
              type="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="Enter Email Address"
              value={formik.values.email}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmailIcon
                      fontSize="small"
                      sx={{ mr: '10px' }}
                    />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <InputField
              required
              error={Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)}
              fullWidth
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
              label="Phone Number"
              name="phoneNumber"
              type="phoneNumber"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="Enter Phone Number"
              value={formik.values.phoneNumber}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PhoneIcon
                      fontSize="small"
                      sx={{ mr: '10px' }}
                    />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <DateField
              error={Boolean(formik.touched.dob && formik.errors.dob)}
              fullWidth
              helperText={formik.touched.dob && formik.errors.dob}
              label="Date of Birth"
              name="dateOfBirth"
              onChange={(date) => formik.setFieldValue('dob', moment(date).format('l'))}
              placeholder="mm/dd/yyyy"
              value={formik.values.dob}
              maxDate={new Date()}
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <Autocomplete
              fullWidth
              options={categoriesList}
              sx={{
                '& .MuiFilledInput-root .MuiFilledInput-input': {
                  px: 1.5,
                  py: 0.75
                }
              }}
              clearOnEscape
              filterSelectedOptions
              defaultValue={categoriesList?.find(
                (category) => category.id === variant?.customerCategory?.id
              )}
              noOptionsText="No Category Found"
              getOptionLabel={(option) => (`${option?.name}`)}
              renderTags={(value, getTagProps) => value.map((option, key) => (
                <Chip
                  variant="outlined"
                  label={`${option.name}`}
                  size="small"
                  {...getTagProps({ key })}
                />
              ))}
              renderInput={({ InputProps, ...rest }) => (
                <TextField
                  {...rest}
                  error={Boolean(formik.touched.categoryId && formik.errors.categoryId)}
                  helperText={formik.touched.categoryId && formik.errors.categoryId}
                  label="Category"
                  placeholder="Search And Select A Category"
                  sx={{
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'background.paper',
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: 'neutral.300',
                      borderRadius: 1,
                      boxShadow: '0px 1px 2px 0px rgba(9, 30, 66, 0.08)',
                      '&.MuiAutocomplete-inputRoot': {
                        p: 0
                      },
                      transition: (theme) => theme.transitions.create([
                        'border-color',
                        'box-shadow'
                      ]),
                      '&:hover': {
                        backgroundColor: 'background.paper'
                      },
                      '&.Mui-focused': {
                        backgroundColor: 'background.paper',
                        boxShadow: (theme) => `${alpha(theme.palette.primary.main,
                          0.25)} 0 0 0 0.2rem`
                      },
                      '& .MuiFilledInput-input': {
                        fontSize: 14,
                        height: 'unset',
                        lineHeight: 1.6,
                        px: 1.5,
                        py: 0.75
                      },
                      '&.Mui-disabled': {
                        backgroundColor: 'action.disabledBackground',
                        boxShadow: 'none',
                        borderColor: alpha('#D6DBE1', 0.5)
                      },
                      ':not(.MuiInputBase-adornedStart)': {
                        p: 0
                      }
                    }
                  }}
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    ...InputProps
                  }}
                  InputLabelProps={{
                    shrink: true,
                    sx: {
                      color: 'text.primary',
                      fontSize: 14,
                      fontWeight: 500,
                      mb: 0.5,
                      position: 'relative',
                      transform: 'none'
                    }
                  }}
                />
              )}
              ChipProps={{ variant: 'outlined' }}
              // eslint-disable-next-line max-len
              onChange={(event, value) => {
                if (value !== null) {
                  formik.setFieldValue('categoryId', value.id);
                }
              }}
              onInputChange={(event, newInputValue) => {
                setCategoryName(newInputValue);
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <InputField
              // required
              error={Boolean(formik.touched.deliveryLocation && formik.errors.deliveryLocation)}
              fullWidth
              helperText={formik.touched.deliveryLocation && formik.errors.deliveryLocation}
              label="Delivery Location"
              name="deliveryLocation"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="Enter Delivery Location"
              value={formik.values.deliveryLocation}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LocationOn
                      fontSize="small"
                      sx={{ mr: '10px' }}
                    />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          {((merchantData?.integratedWithTIMS || merchantData?.integratedWithETIMS)) && (
            <Grid
              item
              xs={4}
              sx={{
                justifyContent: { xs: 'center', sm: 'flex-start' },
                marginBottom: 1,
              }}
            >
              <InputField
                error={Boolean(formik.touched.buyersPIN && formik.errors.buyersPIN)}
                helperText={formik.touched.buyersPIN && formik.errors.buyersPIN}
                label="Buyer's KRA pin"
                name="buyersPIN"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Enter Buyer's KRA pin"
                text
                value={formik.values.buyersPIN}
                sx={{
                  textDecoration: 'uppercase',
                }}
              />
            </Grid>
          )}
          <Grid
            container
            xs={12}
            spacing={2}
          >
            {/* Customer fields */}
            {(other?.presentingBeneficiariesOnPOSEnabled || merchantData?.presentingBeneficiariesOnPOSEnabled) && (
              <Grid
                item
                xs={12}
                mt={2}
              >
                <Button
                  startIcon={<PlusIcon />}
                  onClick={handleAddBeneficiary}
                >
                  Add Children
                </Button>
              </Grid>
            )}
          </Grid>
          {(other?.presentingBeneficiariesOnPOSEnabled || merchantData?.presentingBeneficiariesOnPOSEnabled) && beneficiaries.map((beneficiary, index) => (
            <CrmCustomerBeneficiaries
              xs={12}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              beneficiary={beneficiary}
              onChange={(updatedBeneficiary) => handleBeneficiaryChange(index, updatedBeneficiary)}
              onRemove={() => handleRemoveBeneficiary(index)}
              sx={{ mt: 2 }}
            />
          ))}
          <Grid
            item
            xs={12}
          >
            <Divider
              sx={{ bgcolor: (theme) => theme.palette.divider }}
              style={{
                border: 'none',
                height: 2,
                margin: 0,
              }}
            />
          </Grid>
          {formik.values.extraDataMap.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
              <Grid
                item
                md={12}
                xs={12}
                sx={{ display: 'flex' }}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={6}
                  >
                    <InputField
                      disabled
                      error={Boolean(getItemError(index, 'id'))}
                      fullWidth
                      helperText={getItemError(index, 'id')}
                      label="Custom Field Name"
                      name={`extraDataMap[${index}].id`}
                      onBlur={formik.handleBlur}
                      onChange={(event) => {
                        formik.setFieldValue(`extraDataMap[${index}].id`, event.target.value);
                        const dataType = customFields.find(
                          (field) => field.id === event.target.value
                        );
                        formik.setFieldValue(`extraDataMap[${index}].type`, dataType.type);
                        formik.setFieldValue(`extraDataMap[${index}].configuredValues`, dataType.configuredValues);
                      }}
                      placeholder="Enter custom field name"
                      value={item.id}
                      select
                    >
                      {customFields.length > 0 ? customFields.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                        >
                          {option.name}
                        </MenuItem>
                      )) : (
                        <MenuItem
                          value=""
                        >
                          <em>Add custom field first</em>
                        </MenuItem>
                      )}
                    </InputField>
                  </Grid>
                  {(formik.values.extraDataMap[index].type === 'Text')
                    && (
                      <Grid
                        item
                        xs={6}
                      >
                        <InputField
                          error={Boolean(getItemError(index, 'value'))}
                          fullWidth
                          helperText={getItemError(index, 'value')}
                          label="Custom Field Value"
                          name={`extraDataMap[${index}].value`}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          placeholder="Enter custom field value"
                          value={item.value}
                          multiline
                          rows={2}
                        />
                      </Grid>
                    )}
                  {(formik.values.extraDataMap[index].type === 'Selection'
                    || formik.values.extraDataMap[index].type === 'Toggle')
                    && (
                      <Grid
                        item
                        xs={6}
                      >
                        <InputField
                          error={Boolean(getItemError(index, 'value'))}
                          fullWidth
                          helperText={getItemError(index, 'value')}
                          label="Custom Field Value"
                          name={`extraDataMap[${index}].value`}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          placeholder="Choose custom field value"
                          value={item.value}
                          select
                        >
                          {formik.values.extraDataMap[index].configuredValues.map((option) => (
                            <MenuItem
                              key={option}
                              value={option}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </InputField>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Fragment>
          ))}
          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.shippingAddress && formik.errors.shippingAddress)}
              fullWidth
              helperText={formik.touched.shippingAddress && formik.errors.shippingAddress}
              label="Additional Information (e.g Delivery Address)"
              name="shippingAddress"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="Enter additional information"
              value={formik.values.shippingAddress}
              multiline
              rows={3}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            {fileUploadSections.map((fileIndex) => (
              <FileUpload
                key={fileIndex}
                formik={formik}
                handleFileUpload={handleFileUpload}
                fileIndex={fileIndex}
                fileUploadProgress={fileUploadProgress}
              />
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {formik.errors.submit && (
          <FormHelperText error>
            {formik.errors.submit}
          </FormHelperText>
        )}
        <Button
          color="primary"
          onClick={onClose}
          variant="contained"
          sx={{ marginLeft: '20px' }}
        >
          Cancel
        </Button>
        <LoadingButton
          disabled={formik.isSubmitting}
          loading={formik.isSubmitting}
          color="success"
          onClick={() => { formik.handleSubmit(); }}
          variant="contained"
        >
          {mode === 'update' ? 'Update Customer' : 'Add Customer'}
        </LoadingButton>
      </DialogActions>
      <ConfirmationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Dialog>
  );
};
CrmCustomerDialog.defaultProps = {
  open: false
};

CrmCustomerDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExited: PropTypes.func,
  onVariantsChange: PropTypes.func,
  variant: PropTypes.object,
  // errors: PropTypes.string
};
