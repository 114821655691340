import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  Badge,
  Box,
  IconButton,
  Typography,
  Popover,
  List,
  ListItem,
  ListSubheader,
  Snackbar,
  Alert
} from '@material-ui/core';
import { DangerousOutlined } from '@material-ui/icons';
import { usePopover } from '../hooks/use-popover';
import { Bell as BellIcon } from '../icons/bell';
import { Sparkles as SparklesIcon } from '../icons/sparkles';
import { useMerchantContext } from '../contexts/merchant-context';
import { getMerchantId } from '../utils/merchant-info';

export const NotificationsPopover = (props) => {
  const { merchantData, isLoading, fetchMerchantData } = useMerchantContext();
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({});
  const merchantId = getMerchantId();
  useEffect(async () => {
    if (!merchantData?.id && !isLoading) {
      await fetchMerchantData(merchantId);
    }
    if (merchantData?.notifications) {
      merchantData?.notifications.forEach((notification) => {
        if (notification.key === 'billing') {
          setSnackBarMessage(notification);
          setOpenSnackBar(false);
        }
      });
    }
  }, [merchantData?.id]);

  const merchantNotifications = merchantData?.notifications
    && merchantData.notifications.length > 0
    ? merchantData.notifications.map((notification) => ({
      id: notification.key,
      content: `You have an ${notification?.message.status} payment of ${notification?.message.amountDue}`,
      icon: notification?.key === 'billing' ? DangerousOutlined : SparklesIcon,
      createdAt: notification.message.createdAt || new Date().getTime(),
      iconColor: `${notification?.status}.main`,
      title: notification.title
    })) : [];

  return (
    <>
      <Badge
        color="success"
        variant="dot"
        {...props}
      >
        <IconButton
          onClick={handleOpen}
          ref={anchorRef}
          sx={{
            color: 'primary.contrastText'
          }}
        >
          <BellIcon />
        </IconButton>
      </Badge>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320 }
        }}
      >
        <List>
          <ListSubheader sx={{ py: 1 }}>
            Notifications
          </ListSubheader>
          {merchantNotifications.map((notification, index) => {
            const { title, content, createdAt, icon: Icon, iconColor } = notification;

            return (
              <ListItem
                disableGutters
                divider={merchantNotifications.length > index + 1}
                key={notification.id}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  p: 2
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <Icon
                    fontSize="small"
                    sx={{ color: iconColor }}
                  />
                  <Typography
                    color="textPrimary"
                    sx={{ ml: 1.25 }}
                    variant="body1"
                  >
                    {title}
                  </Typography>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body2"
                >
                  {content}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="caption"
                >
                  {format(createdAt, 'MMM dd, yyyy')}
                </Typography>
              </ListItem>
            );
          })}
        </List>
      </Popover>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        spacing={2}
      >
        <Alert
          onClose={() => setOpenSnackBar(false)}
          severity={snackBarMessage?.status}
          sx={{ width: '100%', 'z-index': '9999' }}
        >
          {snackBarMessage?.message}
        </Alert>
      </Snackbar>

    </>
  );
};
