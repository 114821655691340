import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {}
  },
  sw: {
    translation: {
      'Language changed': 'Lugha ilibadilika',
      'Blank Page': 'Ukurasa mtupu',
      'Card Headers': 'Vichwa vya Kadi',
      'Data States': 'Takwimu Nchi',
      'Data Stats': 'Takwimu za Takwimu',
      'General Settings': 'Mipangilio ya Jumla',
      'Image Uploader': 'Kipakiaji Picha',
      'Page Headers': 'Vichwa vya Ukurasa',
      'User Management': 'Usimamizi wa Mtumiaji',
      Users: 'Watumiaji',
      'User Group': 'Kikundi cha Mtumiaji',
      Account: 'Akaunti',
      Activity: 'Shughuli',
      Billing: 'Kutoza',
      Buttons: 'Vifungo',
      Colors: 'Rangi',
      Components: 'Vipengele',
      Create: 'Unda',
      Customers: 'Wateja',
      Details: 'Maelezo',
      Documentation: 'Nyaraka',
      Foundation: 'Msingi',
      Inputs: 'Pembejeo',
      Insights: 'Ufahamu',
      Inventory: 'Hesabu',
      Invoices: 'Ankara',
      List: 'Orodha',
      Lists: 'Orodha',
      Notifications: 'Arifa',
      Onboarding: 'Kupanda',
      Orders: 'Maagizo',
      Organization: 'Shirika',
      Overview: 'Maelezo ya jumla',
      Preview: 'Hakiki',
      Products: 'Bidhaa',
      Reports: 'Ripoti',
      Sales: 'Mauzo',
      Shadows: 'Vivuli',
      Summary: 'Muhtasari',
      Tables: 'Meza',
      Team: 'Timu',
      Typography: 'Uchapaji'
    }
  }
};

export const initializeI18n = (lng) => {
  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false
      }
    });
};
