// import { useState, useEffect, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import {
  Box,
  Container,
  // Skeleton,
  // Typography
} from '@material-ui/core';
import { useState } from 'react';
// import { useMounted } from '../hooks/use-mounted';
// import { ExclamationOutlined as ExclamationOutlinedIcon } from '../icons/exclamation-outlined';
// import jwtAxios from '../api/jwt-api';

export const MerchantAdmin = () => {
  const [status, setStatus] = useState(false);
  console.log(status, setStatus);
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box sx={{ py: 2 }}>
          {/* <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography
              color="textPrimary"
              variant="h4"
            >
              {merchantState.data.businessName}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
          </Box> */}
        </Box>
        {/* {renderContent()} */}
        <Outlet />
      </Container>
    </Box>
  );
};
