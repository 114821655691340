import { useState } from 'react';
import { Link as BrowserLink, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Visibility, VisibilityOff } from '@material-ui/icons';
// import { alpha } from '@material-ui/core/styles';
import {
  Alert,
  Button,
  Card,
  CardContent,
  Collapse,
  Grid,
  InputAdornment,
  Typography,
  IconButton,
  Stack
} from '@material-ui/core';
import { InputField } from '../input-field';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';
import { Logo } from '../logo';

export const LoginJwt = () => {
  const [showPassword, setShowPassword] = useState(false);
  const mounted = useMounted();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const { login } = useAuth();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: null
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      password: Yup.string().max(255).required('Password is required'),
      policy: Yup.boolean()
    }),
    onSubmit: async (values, helpers) => {
      try {
        await login(values.email, values.password);

        if (mounted.current) {
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
        }
      } catch (err) {
        if (mounted.current) {
          helpers.setStatus({ success: false });
          helpers.setErrors({
            submit: err.response.data.description
          });
          helpers.setSubmitting(false);
        }
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card
        sx={{
          boxShadow: '4px 9px 23px -3px rgba(0,0,0,0.54)',
          width: '400px',
          padding: '20px 40px',
        }}
      >
        <CardContent>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            sx={{
              mb: 3
            }}
          >
            <Logo />
            <Typography
              color="textPrimary"
              variant="h5"
              // sx={{
              //   color: '#0b1a2a'
              // }}
            >
              Sign In
            </Typography>
            {/* <Button
          color="primary"
          component={BrowserLink}
          to="/register"
          variant="text"
        >
          Sign Up
        </Button> */}
          </Stack>
          {formik.errors.submit && (
          <Collapse in={open}>
            <Alert
              onClose={() => { setOpen(false); }}
              severity="error"
              sx={{ mb: 1 }}
            >
          &nbsp;
              <strong>
                {formik.errors.submit}
              </strong>
          &nbsp;
            </Alert>
          </Collapse>
          )}
          {location.state?.status === 200 && (
          <Collapse in={open}>
            <Alert
              onClose={() => { setOpen(false); }}
              severity="success"
              sx={{ mb: 2 }}
            >
              {location.state?.message}
            </Alert>
          </Collapse>
          )}
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <InputField
                autoFocus
                error={Boolean(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Email address"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                value={formik.values.email}
                // InputLabelProps={{
                //   shrink: true,
                //   sx: {
                //     color: '#0b1a2a',
                //     fontSize: 14,
                //     fontWeight: 500,
                //     mb: 0.5,
                //     position: 'relative',
                //     transform: 'none'
                //   }
                // }}
                // sx={{
                //   '&.MuiFilledInput-root': {
                //     backgroundColor: '#FFFFFF'
                //   },
                //   '&:hover': {
                //     backgroundColor: '#ffffff'
                //   },
                //   '&.Mui-focused': {
                //     backgroundColor: 'background.paper',
                //     boxShadow: '#f26633 0 0 0 0.2rem'
                //   },
                // }}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <InputField
                error={Boolean(formik.touched.password && formik.errors.password)}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label="Password"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Button
                color="primary"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Sign In
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Button
                color="primary"
                component={BrowserLink}
                to="/password-recovery"
                variant="text"
              >
                Forgot password
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};
