import { createContext, useEffect, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import jwtAxios, { setAuthToken } from '../api/jwt-api';
import { authApi } from '../api/auth';
import { useSettings } from './settings-context';
import { useMerchantContext } from './merchant-context';
import { baseUrlConfig } from '../config';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  // PASSWORD_SETUP: (state, action) => {
  //   const { user } = action.payload;

  //   return {
  //     ...state,
  //     isAuthenticated: true,
  //     user
  //   };
  // }
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

export const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  // passwordSetup: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [branchesList, setBranchesList] = useState([]);
  const { settings, saveSettings } = useSettings();
  const { setMerchantData, setMerchantLogo } = useMerchantContext();
  const handleChangeSelectedBranch = (branch) => {
    const loggedInMerchant = JSON.parse(localStorage.getItem('user'));
    loggedInMerchant.selectedBranch = branch;
    localStorage.removeItem('register');
    localStorage.removeItem('shift');
    localStorage.setItem('user', JSON.stringify(loggedInMerchant));
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const loggedInUser = window.localStorage.getItem('user');

        if (loggedInUser) {
          const user = JSON.parse(loggedInUser);
          const cookies = new Cookies();
          setAuthToken(cookies.get('token'));
          setBranchesList(user.branches);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };
    initialize();
  }, []);

  const login = async (email, password) => {
    console.log('Env ', process.env.REACT_APP_API_BASE_URL);
    const body = { email, password };
    const signedKey = await jwtAxios.post('encrypt-login-credentials', body);
    // log the respose status code:
    const formData = new FormData();
    formData.append('signedKey', signedKey.data);

    const loginAxios = axios.create({
      baseURL: baseUrlConfig.apiBaseUrl,
      headers: {
        'Content-Type': 'multipart/form-data;boundary=<calculated when request is sent>',
      },
    });
    const res = await loginAxios.post('login', formData);
    const {
      accessToken,
      firstName,
      lastName,
      branches,
      company,
      userId,
      companyId,
      phoneNumber,
      userGroup,
      userType,
      shiftActive,
      additionalDiscount,
      expressStockUpdateAllowed,
      productNotificationAllowed,
      branchCollectionMappingEnabled,
      purchasePriceAllowedOnPOS,
      presentingBeneficiariesOnPOSEnabled,
      waiverIDEnabled,
      logoUrl,
      merchantLogo,
      integratedWithTIMS,
      preferredThemeColor
    } = res.data.data;
    const user = {};
    user.name = `${firstName} ${lastName}`;
    user.group = userGroup.name;
    user.type = userType;
    user.mobile = phoneNumber;
    user.email = email;
    user.company = company;
    user.companyId = companyId;
    user.userId = userId;
    user.branches = branches;
    user.shiftActive = shiftActive;
    user.additionalDiscount = additionalDiscount;
    user.waiverIDEnabled = waiverIDEnabled;
    user.presentingBeneficiariesOnPOSEnabled = presentingBeneficiariesOnPOSEnabled;
    user.expressStockUpdateAllowed = expressStockUpdateAllowed;
    user.productNotificationAllowed = productNotificationAllowed;
    user.purchasePriceAllowedOnPOS = purchasePriceAllowedOnPOS;
    user.merchantLogo = merchantLogo || logoUrl;
    user.integratedWithTIMS = integratedWithTIMS;
    // eslint-disable-next-line prefer-destructuring
    user.selectedBranch = branches[0];
    user.branchCollectionMappingEnabled = branchCollectionMappingEnabled;
    user.preferredThemeColor = preferredThemeColor;
    localStorage.setItem('user', JSON.stringify(user));
    window.localStorage.setItem('etimsLiveSync', true);
    setAuthToken(accessToken);
    setBranchesList(branches);
    setMerchantData(user);
    setMerchantLogo(logoUrl);
    saveSettings({
      ...settings,
      theme: user.preferredThemeColor
    });
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    const cookies = new Cookies();
    delete jwtAxios.defaults.headers.common.Authorization;
    cookies.remove('token', { path: '/' });
    // localStorage.removeItem('accessToken');
    // localStorage.removeItem('user');
    localStorage.clear();
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
    const accessToken = await authApi.register({ email, name, password });
    const user = await authApi.me(accessToken);

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        branchesList,
        handleChangeSelectedBranch
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
