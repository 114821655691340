/* eslint-disable max-len */
import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const ErrorContext = createContext();

export const useError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error('useError must be used within an ErrorProvider');
  }
  return context;
};

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [actionFunc, setActionFunc] = useState(null);
  const [actionButtonTitle, setActionButtonTitle] = useState('Close');

  const showError = (message, code = 400, action = null, actionButtonLabel = 'Close') => {
    setError(message);
    setErrorCode(code);
    setActionButtonTitle(actionButtonLabel);
    setActionFunc(() => action);
  };

  const clearError = () => {
    setError(null);
    setErrorCode(null);
    setActionButtonTitle('Close');
    setActionFunc(null);
  };

  return (
    <ErrorContext.Provider value={{ error, showError, clearError, errorCode, actionFunc, actionButtonTitle }}>
      {children}
    </ErrorContext.Provider>
  );
};

ErrorProvider.propTypes = {
  children: PropTypes.node.isRequired
};
