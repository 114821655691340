import React from 'react';
import { Card, CardContent, Typography, Button, Box } from '@material-ui/core';
import { Warning as WarningIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

const UrgentAlert = ({ message, actionText, onActionClick, variant }) => (
  <Card
    sx={{
      backgroundColor: variant === 'warning' ? '#FFD35A' : '#ffb8ad',
      margin: '-5px',
      minHeight: 70,
    }}
  >
    <CardContent
      sx={{
        padding: 2,
        paddingBottom: '14px !important',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
      >
        <WarningIcon sx={{ color: variant === 'Upcoming' ? '#f57c00' : '#f44336', marginRight: 1 }} />
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, color: variant === 'warning' ? '#000' : '##ef0000' }}
        >
          {message}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={onActionClick}
        >
          {actionText}
        </Button>
      </Box>
    </CardContent>
  </Card>
);

UrgentAlert.defaultProps = {
  actionText: 'Action',
  onActionClick: () => { }
};

UrgentAlert.propTypes = {
  message: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  onActionClick: PropTypes.func,
  variant: PropTypes.string
};

export default UrgentAlert;
