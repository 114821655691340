import { useEffect, useState } from 'react';
import jwtAxios from '../api/jwt-api';
import { handleErrors } from '../services/error-service';

function getUserInfoFromLocalStorage() {
  const loggedInUser = localStorage.getItem('user');
  if (!loggedInUser) {
    return null;
  }

  const user = JSON.parse(loggedInUser);
  return user;
}

export function getMerchantId() {
  const user = getUserInfoFromLocalStorage();
  if (!user) {
    return null;
  }

  return user.companyId;
}

export function isMerchantAdmin() {
  const user = getUserInfoFromLocalStorage();
  return user && user.group === 'Merchant Admin';
}

export function isAccountant() {
  const user = getUserInfoFromLocalStorage();
  return user && user.group === 'Accountant';
}

export function isSuperAdmin() {
  const user = getUserInfoFromLocalStorage();
  return user && user.group === 'Super Admin';
}

export function isCashier() {
  const user = getUserInfoFromLocalStorage();
  return user && user.group === 'Cashier';
}

export function getSelectedBranch() {
  const user = getUserInfoFromLocalStorage();
  return user && user.selectedBranch;
}

export function branchCurrency() {
  const user = getUserInfoFromLocalStorage();
  return user && user.selectedBranch.currency;
}

export function useFetchBranchData() {
  const [branchData, setBranchData] = useState(null);
  const user = getUserInfoFromLocalStorage();

  useEffect(() => {
    const fetchBranchData = async () => {
      try {
        const response = await jwtAxios.get(`/branch/${user.selectedBranch.id}/${user.companyId}`);
        setBranchData(response.data.data);
        console.log('branchData', response.data.data);
      } catch (error) {
        handleErrors(error);
      }
    };
    fetchBranchData();
  }, [user.companyId, user.selectedBranch.id]);

  return branchData;
}

const merchantInfo = {
  getUserInfoFromLocalStorage,
  getMerchantId,
  isMerchantAdmin,
  getSelectedBranch,
  useFetchBranchData,
};

export default merchantInfo;
