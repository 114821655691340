/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import jwtAxios from '../../api/jwt-api';
import { handleErrors } from '../error-service';
import { getMerchantId, getSelectedBranch } from '../../utils/merchant-info';

// enums for the endpoints
export const endpoints = {
  packagingUnits: 'packaging-units',
  productTypes: 'product-types',
  itemClassificationCodes: 'item-classification-codes',
  unitsOfQuantity: 'units-of-quantity',
  etimsTransactionStatuses: 'transaction-progress-statuses',
  getEtimsBranches: 'select-bhf-list',
  etimsImportsStatuses: 'import-item-statuses',
  merchantDeviceInfo: 'get-merchant-etims-details',
  refreshEtimsCodes: ''

};
const refreshFromEtims = window.localStorage.getItem('etimsLiveSync') === 'true';
const etimsTaxRateType = {
  A: 0.0,
  B: 16.0,
  C: 0.0,
  D: 0.0,
  E: 8.0
};

const defaultController = { pageNo: 0, pageSize: 1000 };
const branchId = getSelectedBranch()?.id;
const merchantId = getMerchantId();

// TODO: Separate ETIMS tax serivice
export const etimsCodesParams = {
  TaxationTypes: 'Taxation Types',
  Countries: 'Countries',
  PaymentTypes: 'Payment Types',
  BranchStatuses: 'Branch Statuses',
  QuantityUnits: 'Quantity Units',
  SaleStatuses: 'Sale Statuses',
  StockMovements: 'Stock Movements',
  TransactionTypes: 'Transaction Types',
  PackingUnits: 'Packing Units',
  ItemTypes: 'Item Types',
  ImportItemStatuses: 'Import Item Statuses',
  RefundReasons: 'Refund Reasons',
  Currencies: 'Currencies',
  TransactionProgressStatuses: 'Transaction Progress Statuses',
  InventoryAdjustmentReasons: 'Inventory Adjustment Reasons',
  SalesReceiptTypes: 'Sales Receipt Types',
  PurchaseReceiptTypes: 'Purchase Receipt Types',
};
const getEtimsCodes = async (codeClassName = null, code = null, controller = defaultController) => {
  try {
    const response = await jwtAxios.get(`/v1/etims/codes?codeClassName=${codeClassName}&code=${code}&pageNo=${controller.pageNo}&pageSize=${controller.pageSize}`);
    return response.data.data;
  } catch (error) {
    handleErrors(error, 'Etims codes ');
    return Promise.reject(error);
  }
};
const getData = async (endpoint, code = null) => {
  const param = code ? `?code=${code}` : '?';
  try {
    const response = await jwtAxios.get(`/v1/etims-code/${endpoint}${param}&branchId=${branchId}&refreshFromEtims=${refreshFromEtims}`);
    return response.data.data;
  } catch (error) {
    handleErrors(error, 'Etims codes ');
    return Promise.reject(error);
  }
};

const getDataWithRefresh = async (endpoint, controller = defaultController, merchantID = merchantId) => {
  try {
    const response = await jwtAxios.get(
      `/v1/etims/${endpoint}?refreshFromEtims=${refreshFromEtims}&pageNo=${controller.pageNo}&pageSize=${controller.pageSize}&branchId=${branchId}&merchantId=${merchantID}`
    );
    return response.data.data;
  } catch (error) {
    console.log('error', error);
    // handleErrors(error, 'Etims codes ');
    return Promise.reject(error);
  }
};

const getDataWithMerchantBranch = async (endpoint) => {
  try {
    const response = await jwtAxios.get(
      `/v1/etims/${endpoint}?merchantId=${merchantId}&branchId=${branchId}`
    );
    return response.data.data;
  } catch (error) {
    console.log('error', error);
    // handleErrors(error, 'Etims codes ');
    return Promise.reject(error);
  }
};

const getPackagingUnits = async (code = null, controller) => getEtimsCodes(etimsCodesParams.PackingUnits, code, controller);
const postEtimsAction = (endpoint) => async () => {
  const response = await jwtAxios.post(`/v1/etims/${endpoint}?branchId=${branchId}&refreshFromEtims=${refreshFromEtims}`);
  return response.data.data;
};

const refreshEtimsCodeAction = postEtimsAction('codes');
const refreshEtimsClassCodeAction = postEtimsAction('item-class-list');
const getBranchInsuranceList = async (controller = defaultController) => getDataWithRefresh('branch-insurances', controller);
const getProductTypes = async (code = null, controller = defaultController) => getEtimsCodes(etimsCodesParams.ItemTypes, code, controller);
const getEtimsImportsStatuses = async (code = null, controller = defaultController) => getEtimsCodes(etimsCodesParams.ImportItemStatuses, code, controller);
// eslint-disable-next-line max-len
const getItemClassificationCodes = async (code = null, controller = defaultController) => getDataWithRefresh(endpoints.itemClassificationCodes, controller);
const getUnitsOfQuantity = async (code = null, controller = defaultController) => getEtimsCodes(etimsCodesParams.QuantityUnits, code, controller);
const getEtimsTransactionStatuses = async (code = null, controller = defaultController) => getEtimsCodes(etimsCodesParams.TransactionProgressStatuses, code, controller);
const getReceiptTypes = async (code = null, controller = defaultController) => getEtimsCodes(etimsCodesParams.SalesReceiptTypes, code, controller);
const getCountries = async (code = null, controller = defaultController) => getEtimsCodes(etimsCodesParams.Countries, code, controller);
const getTaxationTypes = async (code = null, controller = defaultController) => getEtimsCodes(etimsCodesParams.TaxationTypes, code, controller);
const getRefundReasons = async (code = null, controller = defaultController) => getEtimsCodes(etimsCodesParams.RefundReasons, code, controller);
const getEtimsBranches = async (controller = defaultController, merchantID = merchantId) => getDataWithRefresh(
  endpoints.getEtimsBranches,
  refreshFromEtims,
  controller,
  merchantID
);
const getEtimsNotices = async (controller = defaultController) => getDataWithRefresh('notices', controller);
const refreshEtimsNotices = async (controller = defaultController) => getDataWithRefresh('select-notices', controller);
const getEtimsDeviceInfo = async () => getDataWithMerchantBranch(endpoints.merchantDeviceInfo);

export const ProductTaxService = {
  refreshEtimsCodeAction,
  refreshEtimsClassCodeAction,
  getPackagingUnits,
  getProductTypes,
  getItemClassificationCodes,
  getUnitsOfQuantity,
  getTaxationTypes,
  getCountries,
  getReceiptTypes,
  getEtimsTransactionStatuses,
  getEtimsImportsStatuses,
  getEtimsBranches,
  etimsTaxRateType,
  getEtimsDeviceInfo,
  getBranchInsuranceList,
  getRefundReasons,
  getEtimsNotices,
  refreshEtimsNotices
};
