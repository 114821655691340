import axios from 'axios';
import { Cookies } from 'react-cookie';
import { baseUrlConfig } from '../config';

axios.defaults.baseURL = baseUrlConfig.apiBaseUrl;
console.log(process.env.REACT_APP_API_BASE_URL);
const jwtAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    // 'Content-Type': 'application/x-www-form-urlencoded',
    // 'Access-Control-Allow-Origin': '*'
  },
});

// const refreshToken= ()=>{
//   // gets new access token
// }
// https://api.rack.business/api/
// https://api.drift.co.ke/api/
jwtAxios.interceptors.response.use(
  (res) => res, (err) => {
    // err.response.status === 403
    // err.response.data.error === 'invalid_token'
    console.log('Status ', err.response);
    if (err.response && err.response.status === 403) {
      // refreshToken()
      console.log('Need to logout user');
      const cookies = new Cookies();
      delete jwtAxios.defaults.headers.common.Authorization;
      cookies.remove('token', { path: '/' });
      localStorage.clear();
      window.location.href = '/login';
    }
    return Promise.reject(err);
  },
);

export const setAuthToken = (token) => {
  const cookies = new Cookies();
  jwtAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
  cookies.set('token', token, { path: '/' });
};

// const parseJwt = (token) => {
//   try {
//     return JSON.parse(atob(token.split('.')[1]));
//   } catch (e) {
//     return null;
//   }
// };

// class AuthVerify extends Component {
//   constructor(props) {
//     super(props);
//     props.history.listen(() => {
//       const user = JSON.parse(localStorage.getItem("user"));
//       if (user) {
//         const decodedJwt = parseJwt(user.accessToken);
//         if (decodedJwt.exp * 1000 < Date.now()) {
//           props.logOut();
//         }
//       }
//     });
//   }
export default jwtAxios;
