export const ErrorCodes = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  500: 'Internal Server Error',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout'
};

export const CustomErrorCodesEnum = {
  // Account suspended
  1000: 'This feature is suspended. An outstanding bill is overdue by 10 days or more.',
  1100: 'This feature is suspended. An outstanding bill is overdue by 15 days or more.',
  1200: 'This feature is suspended. An outstanding bill is overdue by 20 days or more.',
  1300: 'This feature is suspended. An outstanding bill is overdue by 30 days or more.',
  // Account locked
  2000: 'Merchant account is suspended. Head to the billing page to clear the outstanding bill.',
};
